@import "src/styles/shared.scss";

@include media-breakpoint-up(lg) {
    .modal-xl {
        max-width: $modal-lg;
    }
}

@include media-breakpoint-up(xl) {
    .modal-xl {
        max-width: $modal-xl;
    }
}