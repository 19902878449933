@import "src/styles/shared.scss";

.footer {
    border-top: ($grid-gutter-width / 4) solid theme-color("primary") ;

}

.footer-text {
    font-weight: 400 !important;
    font-size: 14px !important;
}