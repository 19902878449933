@import "src/styles/shared.scss";


.sub-table-state {
    .SingleDatePickerInput {
        display: flex;
        align-items: center;
    }

    .input-group-text {
        min-width: 50px;
    }
}

.font-small {
    font-size: 10pt;

    .form-control {
        font-size: 10pt;
    }
}

.vertical-align-checkbox-items {
    display: flex;
    align-items: center;
}

.indent-sub-question-2 {
    padding-left: 6rem !important;
}

.indent-sub-question-3 {
    padding-left: 8rem !important;
}

.hide-component {
    display: none;
}