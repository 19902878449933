﻿.ReactTable {
    &.-striped {
        .rt-tr {
            &.-warning {
                &.-odd {
                    background: theme-color-level("warning", -9);
                }

                &.-even {
                    background: theme-color-level("warning", -11);
                }
            }
        }
    }
}
