﻿// Functions
//

// Paths
//

$fa-font-path: "~font-awesome/fonts";

// Colors
//




$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ( 
    100: $gray-100, 
    200: $gray-200, 
    300: $gray-300, 
    400: $gray-400, 
    500: $gray-500, 
    600: $gray-600, 
    700: $gray-700, 
    800: $gray-800, 
    900: $gray-900 
) !default;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$colors: ( 
    blue: $blue, 
    indigo: $indigo, 
    purple: $purple, 
    pink: $pink, 
    red: $red, 
    orange: $orange, 
    yellow: $yellow, 
    green: $green, 
    teal: $teal, 
    cyan: $cyan, 
    white: $white, 
    gray: $gray-600, 
    gray-dark: $gray-800 
);

$theme-colors: ( 
    primary: #00447A, 
    secondary: #2E86AB, 
    tertiary: #fea002, 
    success: $green, 
    info: $cyan, 
    warning: $yellow, 
    danger: $red, 
    light: $gray-100, 
    dark: $gray-800,
    input: lighten($black, 85%),
    white: $white
);

// Body
//
// Settings for the `<body>` element.

$body-color: $gray-900;

// Create grayscale

/*color("gray-dark"): #292b2c;
color("gray"): #464a4c;
color("gray")-light: #636c72;
color("gray")-lighter: #eceeef;
color("gray")-lightest: #f7f7f9;*/

//Spacing
$spacer: 1rem;

$spacers: (
    0: 0, 
    1: ($spacer * .25), 
    2: ($spacer * .5),
    3: ($spacer * .75),
    4: $spacer, 
    5: ($spacer * 1.25), 
    6: ($spacer * 1.5), 
    7: ($spacer * 1.75),
    8: ($spacer * 2), 
    9: ($spacer * 2.25), 
    10: ($spacer * 2.5),  
    11: ($spacer * 2.75), 
    12: ($spacer * 3) 
);

$spacer-x: $spacer;
$spacer-y: $spacer;

$border-width: 1px;

//Fonts
//
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem;
$font-size-sm: .875rem;
$font-size-xs: .75rem;
$font-size-xlg: 1.75rem;
$font-family-sans-serif: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-family-h1: GTPressura, Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;


$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$headings-font-weight: $font-weight-light;

// Components
//
// Define common padding and border radius sizes and more.

$modal-xl: 1140px;

$line-height-xlg: (8 / 7);
$line-height-lg: (4 / 3);
$line-height-sm: 1.5;

$border-radius: .25rem;

$border-radius-xlg: .5rem;
$border-radius-lg: .3rem;
$border-radius-sm: .2rem;

$component-active-color: $white;
$component-active-bg: theme-color("primary");

$caret-width: .3em;

$transition-base: all .2s ease-in-out;
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;

//Buttons
//

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-box-shadow: 0 0 0 3px rgba(theme-color("primary"), .25);
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125);


$btn-secondary-color: $white;
$btn-secondary-bg: theme-color("secondary");
$btn-secondary-border: theme-color("secondary");

$btn-secondary-inverse-color: theme-color("secondary");
$btn-secondary-inverse-bg: $white;
$btn-secondary-inverse-border: $btn-secondary-inverse-bg;

$btn-tertiary-color: $white;
$btn-tertiary-bg: theme-color("tertiary");
$btn-tertiary-border: theme-color("tertiary");

$btn-outline-input-color: color("gray-dark");
$btn-outline-input-bg: $white;
$btn-outline-input-border: #ccc;

$btn-padding-x-xlg: 2rem;
$btn-padding-y-xlg: 1rem;

$btn-border-radius-xlg: $border-radius-xlg;


$component-list-item-image-border-radius: 50%;

//Forms
$input-padding-x: .75rem;
$input-padding-y: .5rem;
$input-line-height: 1.25;

$input-bg: $white;
$input-bg-disabled: $gray-200;

$input-color: $body-color;
$input-border-color: rgba($black,.15);
$input-btn-border-width: $border-width; // For form controls and buttons
$input-box-shadow: inset 0 1px 1px rgba($black,.075);

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-bg-focus: $input-bg;
$input-focus-border-color: lighten(theme-color("primary"), 25%);
$input-focus-box-shadow: $input-box-shadow, $btn-focus-box-shadow;
$input-focus-invalid-box-shadow: $input-box-shadow, 0 0 0 3px rgba(theme-color("danger"), 0.25);
$input-focus-warning-box-shadow: $input-box-shadow, 0 0 0 3px rgba(theme-color("warning"), 0.25);
$input-focus-valid-box-shadow: $input-box-shadow, 0 0 0 3px rgba(theme-color("success"), 0.25);
/*$input-border-focus: lighten(theme-color("primary"), 25%);
$input-box-shadow-focus: $input-box-shadow, rgba($input-border-focus, .6);*/
$input-color-focus: $input-color;

$input-color-placeholder: color("gray")-light;

$input-padding-x-sm: .5rem;
$input-padding-y-sm: .25rem;

$input-padding-x-lg: 1.5rem;
$input-padding-y-lg: .75rem;

$input-height: (($font-size-base * $input-line-height) + ($input-padding-y * 2));
$input-height-lg: (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2));
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2));

$input-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

// Z-index master list

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: $zindex-modal-backdrop; //This needs to remain the same as the modal backdrop z-index to work with the NGT created MODAL COMPONENT. ALL CAPS!!!!
$zindex-popover: 1060;
$zindex-tooltip: 1070;

//Select
// control options
$lighter-primary: lighten(#562c85,60%);
$select-input-bg: $input-bg;
$select-input-bg-disabled: $input-bg-disabled;
$select-input-border-color: $input-border-color;
$select-input-border-radius: $input-border-radius;
$select-input-border-focus: $input-focus-border-color; // 
$select-input-box-shadow-focus: $input-focus-box-shadow;
$select-input-border-width: $input-btn-border-width;
$select-input-height: $input-height;
$select-input-internal-height: $input-height;
$select-input-placeholder: $input-color-placeholder;
$select-text-color: $input-color;
$select-link-hover-color: $select-input-border-focus;
$select-input-hover-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
//menu options
$select-option-color: $input-color;
$select-option-bg: $select-input-bg;
$select-option-focused-color: $select-text-color;
$select-option-focused-bg: $lighter-primary; // pale blue
$select-option-selected-color: $select-text-color;
$select-option-selected-bg: theme-color("primary-light"); // lightest blue
$select-option-disabled-color: lighten($select-text-color, 60%);

$select-arrow-width: $select-input-internal-height / 6;

$grid-gutter-width: 30px;

$nav-link-padding: ($grid-gutter-width / 4) ($grid-gutter-width / 2);

$nav-link-active-color: lighten(theme-color("primary"), 10%);