@import "src/styles/shared.scss";

.form-date-group
{
    .ms-DatePicker
    {
        .ms-TextField
        {

            .ms-TextField-wrapper
            {

                .ms-TextField-fieldGroup
                {
                    color: $input-color;
                    border-color: $input-border-color;
                    border-width: $input-btn-border-width;
                    border-radius: $input-border-radius;
                    height: $input-height;
                    line-height: 1.5;
                    font-size: 1rem;
                    align-items: center;

                    .ms-TextField-field
                    {
                        line-height: 0.5;
                        font-size: 1rem;
                        padding: 0.5rem 0.75rem;
                        background: none;
                        border: none;
                        color: $input-color !important;
                    }
                }
            }
        }
    }

    &.is-invalid
    {
        .is-open
        {
            box-shadow: $input-box-shadow, $input-btn-focus-box-shadow !important;
        }

        .ms-TextField-fieldGroup
        {
            border-color: $red !important;
        }
    }

    &.is-valid
    {
        .is-open
        {
            .ms-TextField-fieldGroup
            {
                border-color: $input-focus-border-color !important;
                box-shadow: $input-box-shadow, $input-btn-focus-box-shadow !important;
            }
        }
    }

    .ms-TextField-errorMessage
    {
        display: none;
    }
}

.ms-DatePicker-callout {
    margin-top: 0.5rem;

    .ms-Callout-main {
        min-height: 250px;

    }
}

.ms-DatePicker-day--highlighted
{
    background-color: theme-color("primary") !important;
    color: white !important;

    .ms-DatePicker-day--today
    {
        color: white !important;
        background: none !important;
        font-weight: 400 !important;
    }
}

.ms-DatePicker-day--today
{
    color: $input-color !important;
    background: none !important;
    font-weight: 400 !important;

}