@import "src/styles/shared.scss";
td .generic-form-field
{

    > .validation-messages
    {
        padding: 15px 0px 0px 0px !important;
        min-width: initial !important;
    }
}
.generic-form-field
{
    position: relative;

    > .validation-messages
    {
        width: 100%;
        left: 0;
        right: 0;
        top: 100%;
        bottom: auto;
        position: absolute;
        z-index: 998;

        &:hover
        {
            .validation-message
            {
                display: none;
            }
        }

        &.above
        {
            bottom: 100%;
            top: auto;
        }
    }

    &.focus
    {
        > .validation-messages
        {
            &:hover
            {
                .validation-message
                {
                    display: block;
                }
            }
        }
    }

    .validation-message
    {
        display: none;
    }

    &.focus,
    &:hover
    {
        > .validation-messages
        {

            .validation-message
            {
                display: block;
            }
        }
    }
}
