@import "src/styles/shared.scss";

.Capture-nav {
    .nav-link {
        color: color("white");

        &:hover {
            background: $nav-link-active-color;
        }

        &.active {
            background: $nav-link-active-color;
        }
    }
}