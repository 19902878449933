@import "src/styles/shared.scss";

.indent-sub-question-2 {
    padding-left: 6rem !important;
}

.indent-sub-question-3 {
    padding-left: 8rem !important;
}

.hide-component {
    display: none;
}