@import "src/styles/shared.scss";

.alert-icon {
    max-width: 108px;
    padding: 14px;
    background: rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.alert-container {
    /* IE fix */
    min-width: 300px;
}