@import "src/styles/shared.scss";

.form-summary-details {

    .form-summary-details-info {
        
        &:hover {
            cursor: pointer;

        }
    }


    .badge {

        &:hover {
            cursor: pointer;
        }
    }
}

.-clickable {
    cursor: pointer;

}
