@import "src/styles/shared.scss";

.input-group
{

    > .react-numeric-input
    {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;

        &:not(:last-child)
        {
            .form-control
            {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:not(:first-child)
        {
            .form-control
            {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        b
        {
            display: none !important;
        }
    }
}

.react-numeric-input
{

    b
    {
        display: none !important;
    }
}

.is-warning
{
    border-color: #faebcc;
}
