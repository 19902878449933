.sae-summary
{

    .summary-legend
    {
        position: relative;


        .summary-legend-header
        {
            display: block;
            font-size: 24px;
            font-weight: bold;
            position: relative;
        }

        .summary-legend-box
        {
            display: inline-block;
            width: 30px;
            height: 25px;
            background-color: #d9edf7;
            margin-right: 15px;
        }

        .summary-legend-info
        {
            display: inline-block;
            font-size: 24px;
            font-weight: bold;
            position: relative;
            top: -4px;
        }
    }
}

.latest-data-field
{
    background-color: #d9edf7 !important;
}

.summary-sub-tables
{
    .ReactTable .rt-td
    {
        white-space: normal;
        font-size: 12px;
        overflow: unset;
    }

    .ReactTable .rt-th
    {
        
        overflow: unset;
    }
}

.indent-grouped-label
{
    padding-left: 3rem !important;
}