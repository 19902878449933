@import "src/styles/shared.scss";

.color-bar-loading {
    position: relative;

    &.absolute-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: space-between;

        .loading-bar {
            display: inline-block;
            width: 4px;
            border-radius: 4px;
            animation: loading 1s ease-in-out infinite;

            &:nth-child(1) {
                background-color: theme-color("primary");
                animation-delay: 0;
            }

            &:nth-child(2) {
                background-color: theme-color("tertiary");
                animation-delay: 0.09s;
            }

            &:nth-child(3) {
                background-color: theme-color("primary");
                animation-delay: .18s;
            }

            &:nth-child(4) {
                background-color: theme-color("tertiary");
                animation-delay: .27s;
            }
        }
    }

    @keyframes loading {
        0% {
            transform: scale(1);
        }

        20% {
            transform: scale(1, 2.2);
        }

        40% {
            transform: scale(1);
        }
    }
}
