@import "src/styles/shared.scss";

.form-select-group
{
    position: relative;

    .is-invalid
    {

        .Select-control
        {
            border-color: $danger !important;
        }
    }

    .is-warning
    {
        .Select-control
        {
            border-color: #faebcc !important;
        }
    }
}

.Select
{
    &.is-disabled
    {
        //color: red !important;
        .Select-control
        {
            .Select-placeholder
            {
                color: $select-option-disabled-color;
            }

            .Select-value
            {
                .Select-value-label
                {
                    color: $select-option-disabled-color !important;
                }
            }
        }
    }
}

.min-width-select
{

    .form-select-group
    {
        min-width: 100px;
    }
}

.is-warning
{
    border-color: #faebcc;
}
