@import "src/styles/shared.scss";

/* react-treebeard styling */

.tree-container {
    .list-group-item {
        margin-bottom: -1px;
        border-radius: 0;
    }


    .sub-tree {
    }

    ul {

        &.tree {
            background-color: $white;
            list-style: none;
            padding: 0;
        }

        &.sub-tree {
            background-color: $white;
            list-style: none;
            padding: 0 0 0 $spacer;
        }

    }
}