@import "src/styles/shared.scss";

.form-radio-group {
    margin-right: -$grid-gutter-width / 4;
    margin-left: -$grid-gutter-width / 4;

    .btn-radio {
        flex: 1 1 0 !important;
        margin-right: $grid-gutter-width / 4 !important;
        margin-left: $grid-gutter-width / 4 !important;
        border-bottom-left-radius: $btn-border-radius !important;
        border-bottom-right-radius: $btn-border-radius !important;
        border-top-left-radius: $btn-border-radius !important;
        border-top-right-radius: $btn-border-radius !important;
        box-shadow: none !important;
    }

    &.form-radio-group-default {
        margin-bottom: $spacer * -1;
    }

    .is-invalid {
        border-color: $danger !important;
    }

    /*.form-control-feedback {
        left: -10%;
        right: 0;
    }*/
}

