@import "src/styles/shared.scss";

.Capture-menu {
    background: $nav-link-active-color;

    .nav-link {
        color: color("white");

        &:not([href]):not([tabindex]) {
            color: $gray-500;
        }
    }
}
