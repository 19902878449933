@import "src/styles/shared.scss";


.file-upload {
    position: relative;
    margin-bottom: 0;

    input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
    }

    .input-group-text {
        min-width: 0;
    }
}

.file-input-iframe {
    display: none;
}
