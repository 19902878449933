@import "src/styles/shared.scss";

.header {
    background: #fff;

    > .container {
        padding: ($grid-gutter-width / 2);

        .navbar-toggle {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            padding: ($grid-gutter-width / 2);
            right: 0;

            .icon-bar {
                background: theme-color("primary") !important;
            }
        }

        .app-title {
            color: theme-color("primary");
            font-weight: bold;
            font-size: 36px;
            text-align: right;
        }

        .app-login {
            text-align: right;

            div {
                margin-top: 8px;

                a {
                    text-decoration: none;
                }
            }
        }
    }
}
