@import "src/styles/shared.scss";

.checkbox-set
{
    .custom-control-input
    {
        &.is-valid
        {
            &:checked
            {
                ~ .custom-control-indicator
                {
                    background-color: theme-color("success");
                }
            }

            &:focus
            {
                ~ .custom-control-indicator
                {
                    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 3px theme-color("success");
                    box-shadow: 0 0 0 1px #fff, 0 0 0 3px theme-color("success");
                }
            }
        }

        &.is-invalid
        {
            &:checked
            {
                ~ .custom-control-indicator
                {
                    background-color: theme-color("danger");
                }
            }

            &:focus
            {
                ~ .custom-control-indicator
                {
                    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 3px theme-color("danger");
                    box-shadow: 0 0 0 1px #fff, 0 0 0 3px theme-color("danger");
                }
            }
        }

        &.is-warning
        {
            &:checked
            {
                ~ .custom-control-indicator
                {
                    background-color: theme-color("warning");
                }
            }

            &:focus
            {
                ~ .custom-control-indicator
                {
                    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 3px theme-color("warning");
                    box-shadow: 0 0 0 1px #fff, 0 0 0 3px theme-color("warning");
                }
            }

            ~ .custom-control-label
            {
                color: theme-color("warning");
            }
        }

        &:checked
        {
            ~ .custom-control-indicator
            {
            }
        }
    }
}

.custom-checkbox
{
    &.hide-label
    {
        width: 1rem;
        padding-left: 1rem;

        .custom-control-input
        {
            top: 0;
            left: 0;
        }
    }
}

.custom-control-label
{
    &.is-warning
    {
        color: theme-color("warning");
    }
}
